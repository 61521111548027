import api from './api'

export default {
  get(body) {
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
    }
    return api().post('/config/get', body, config)
  },
  set(body) {
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
    }
    return api().post('/config/set', body, config)
  }
}