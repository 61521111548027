<template>
  <div class="modal" :class="{ 'is-active': exibirModal }">
    <div class="modal-background" @click="fecharModal($event)"></div>
    <div class="modal-content">
      <div id="texto-copiar" class="box my-2">
        {{ texto }}
      </div>
      <div class="botoes">
        <v-icon color="blue" @click="copiar()"> mdi-content-copy </v-icon>
      </div>
    </div>
    <button
      class="modal-close is-large ponteiro"
      aria-label="close"
      @click="fecharModal($event)"
    >
      X
    </button>
  </div>
</template>

<script>
export default {
  name: "ModalTexto",
  components: {},
  props: {
    texto: {
      type: String,
      default: "",
    },
    valor: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    copiar() {
      const element = document.querySelector("#texto-copiar");
      const range = document.createRange();
      range.selectNode(element);
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    },
    preventClose() {
      document.getElementById("texto-copiar").stopPropagation();
    },
    fecharModal() {
        //console.error(`${event.target.classList}`)
    //   if (event.target.id === "texto-copiar") {
    //     console.error('fecharModal evitado!')
    //     return; 
    //   }
      this.$emit("fechar");
    },
  },
  watch: {
    valor(val) {
      this.exibirModal = val;
    },
  },
  data() {
    return {
      exibirModal: false,
    };
  },
  mounted() {},
};
</script>

<style scoped>
.modal {
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.modal-content {
  width: fit-content;
  max-width: 80%;
  max-height: 80%;
  background-color: #fff;
  border-radius: 5px;
  padding: 1.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 200;
}

.modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  color: rgb(200, 200, 200);
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.modal-close:hover {
  color: white;
}

@media (max-width: 768px) {
  .modal-content {
    width: 90%;
  }
}

.botoes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>