<template>
  <v-container>
    <div class="header">
      <span>
        <h3>Monitoramento de Performance dos SQL's</h3>
        <p>Monitoramento das Queries nos Bancos de Dados</p>
      </span>
    </div>
    <div class="header">
      <v-row>
        <v-col cols="12">
          <h4>Configurar notificações via email para queries lentas:</h4>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            label="Destinatários"
            placeholder="Insira os emails separados por ','"
            outlined
            v-model="sqlEmailDests"
            @change="configChanged = true"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            label="Tempo de Corte"
            placeholder="Considerar queries que demorem a partir de (ms)"
            outlined
            v-model="sqlEmailCorte"
            @change="configChanged = true"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-row align="center" justify="end">
            <v-col>
              <v-btn
                :disabled="!configChanged"
                color="success"
                @click="salvarConfig()"
                >Salvar</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="header">
      <div style="width: 80vw">
        <v-row style="width: 100%">
          <v-col cols="12" md="6" lg="3">
            <v-select
              hide-details
              return-object
              item-text="datname"
              item-value="id"
              :items="dbs"
              label="Filtrar por Banco de Dados"
              v-model="bancoSelecionado"
              outlined
              @change="invalidarResultados()"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <v-select
              hide-details
              :items="states"
              label="Estado da Conexão"
              item-text="description"
              item-value="state"
              v-model="estadoSelecionado"
              return-object
              outlined
              @change="invalidarResultados()"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <v-text-field
              label="Tempo de Corte"
              v-model="tempoCorte"
              outlined
              @change="invalidarResultados()"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <v-row align="center" justify="end">
              <v-col>
                <v-btn color="primary" @click="buscarQueries()">Filtrar</v-btn>
              </v-col>
              <v-col>
                <v-btn color="warning" @click="limparFiltro()">Limpar Filtro</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
    <section class="wrapper-list" v-if="loading">
      <!-- lista de sqls -->
      <v-data-table
        :items="sqls"
        :headers="headers"
        :items-per-page="50"
        :no-data-text="`Clique em filtrar para pesquisar novamente.`"
      >
        <template v-slot:[`item.query_part`]="{ item }">
          <div style="display: flex; align-items: center">
            <div>{{ item.query_part }}</div>
            <v-spacer></v-spacer>
            <v-icon color="blue" class="btnSQL" @click="abrirSQL(item.query)">
              mdi-database-search
            </v-icon>
          </div>
        </template>
      </v-data-table>
    </section>
    <ListSkeleton v-if="!loading" />
    <ModalTexto
      :valor="exibirModalSQL"
      :texto="textoSQL"
      @fechar="exibirModalSQL = false"
    ></ModalTexto>
  </v-container>
</template>

<script>
import ListSkeleton from "@/components/Loading/ListSkeleton.vue";
import SQLServico from "@/services/sql.service.js";
import ModalTexto from "@/components/Modal/ModalTexto";
import ConfigServico from "@/services/config.service.js";

export default {
  components: { ListSkeleton, ModalTexto },
  data() {
    return {
      bancoSelecionado: { id: 0, datname: "Todos" },
      dbs: [],
      headers: [
        { text: "PId", align: "start", value: "pid" },
        { text: "DB", value: "datname" },
        { text: "Query", value: "query_part" },
        { text: "Estado", value: "state" },
        { text: "Início", value: "query_start" },
        { text: "Mudança", value: "state_change" },
        { text: "Tempo(ms)", align: "end", value: "diff_ms" },
      ],
      states: [
        { id: 0, state: "Todos", description: "Todos os estados" },
        { id: 1, state: "active", description: "active - Conexão está em uso" },
        { id: 2, state: "idle", description: "idle - Conexão está ociosa" },
        {
          id: 3,
          state: "idle in transaction",
          description:
            "idle in transaction - Conexão está em uma transação, mas não executando nenhuma consulta",
        },
        {
          id: 4,
          state: "idle in transaction (aborted)",
          description:
            "idle in transaction (aborted) - Conexão estava em uma transação, mas ocorreu um erro e a transação foi abortada",
        },
        {
          id: 5,
          state: "fastpath function call",
          description:
            "fastpath function call - Conexão está executando uma função 'fast-path'",
        },
        {
          id: 6,
          state: "disabled",
          description:
            "disabled - Conexão está desabilitada devido a alguma configuração do servidor ou devido a problemas de conexão",
        },
        {
          id: 7,
          state: "waiting",
          description:
            "waiting - Conexão está aguardando algum recurso, como uma trava de acesso a uma tabela ou um bloqueio devido a um conflito de transações",
        },
        {
          id: 8,
          state: "unknown",
          description: "unknown - Estado da conexão é desconhecido",
        },
      ],
      estadoSelecionado: { id: 0, state: "Todos", description: "" },
      loading: false,
      sqls: [],
      tempoCorte: 100.0,
      exibirModalSQL: false,
      textoSQL: "",
      sqlEmailDests: "",
      sqlEmailCorte: 3000,
      configChanged: false,
    };
  },
  methods: {
    abrirSQL(sql) {
      this.textoSQL = sql;
      this.exibirModalSQL = true;
    },

    invalidarResultados() {
      this.sqls = [];
    },

    limparFiltro() {
      this.bancoSelecionado = { id: 0, datname: "Todos" };
      this.estadoSelecionado = { id: 0, state: "Todos", description: "" };
      this.tempoCorte = 100.0;
    },

    async buscarQueries() {
      let params = {
        datname: this.bancoSelecionado.id
          ? this.bancoSelecionado.datname
          : null,
        state: this.estadoSelecionado.id ? this.estadoSelecionado.state : null,
        diff_ms: parseFloat(this.tempoCorte),
      };
      await SQLServico.buscarQueries(params)
        .then((res) => {
          this.sqls = res.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },

    async buscarDBs() {
      let params = {};
      await SQLServico.buscarQueryDBs(params)
        .then((res) => {
          this.dbs = res.data;
          this.dbs.unshift({ id: 0, datname: "Todos" });
        })
        .catch((e) => {
          console.error(e);
        });
    },

    async buscarConfig() {
      let params = {};
      await ConfigServico.get(params)
        .then((res) => {
          this.sqlEmailDests = res.data.sqlEmailDests;
          this.sqlEmailCorte = res.data.sqlEmailCorte;
        })
        .catch((e) => {
          console.error(e);
        });
    },

    async salvarConfig() {
      let params = {
        sqlEmailDests: this.sqlEmailDests,
        sqlEmailCorte: this.sqlEmailCorte,
      };
      await ConfigServico.set(params)
        .then(() => {
          this.configChanged = false;
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
  async mounted() {
    this.loading = true;
    await this.buscarConfig();
    await this.buscarDBs();
    await this.buscarQueries();
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  padding: 1em;
  margin: 0 auto;
  width: 80vw;
  max-width: 100%;

  > h3 {
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
  background: rgba(215, 211, 222, 0.5);
  height: fit-content;
  border-radius: 10px;
  > span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.wrapper-list {
  margin: 0 auto;
  width: 80vw;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    width: 90vw;
  }
}

.containerFiltros {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 80vw;
  margin: auto;
  margin-bottom: 20px;
}

.btnSQL {
  background-color: azure;
  padding: 5px;
  border-radius: 5px;
}
</style>