<template>
  <div>
    <ListaSQL />
  </div>
</template>

<script>
import ListaSQL from '@/components/SQL/ListaSQL'

export default {
  components: {ListaSQL},
  data(){
    return{
      
    }
  },
  mounted(){
    
  },
  methods: {
    
  }
}
</script>

<style>

</style>